import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import { Header, H1 } from '../components/Typography'

class Title extends React.Component {
  state = {
    color: '#f00',
  }

  toggle = () => {
    this.setState({
      color: this.state.color === '#f00' ? '#0f0' : '#f00',
    })
  }

  render() {
    const style = {
      cursor: 'pointer',
      color: this.state.color,
    }
    return (
      <H1 onClick={this.toggle} style={style}>
        Page 2
      </H1>
    )
  }
}

const SecondPage = () => {
  return (
    <Layout>
      <Header>
        <Title />
        <Link to="/">Back</Link>
      </Header>
    </Layout>
  )
}

export default SecondPage

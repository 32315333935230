import React from 'react'
import styled from 'styled-components'

export const Heading = styled.h1`
  font: 400 2rem / 1.2 Quattrocento, 'Sans-Serif';
  margin-bottom: 2rem;
  margin-top: 0;

  text-rendering: optimizeLegibility;
  font-feature-settings: 'kern' 1, 'kern';
  font-kerning: normal;
  letter-spacing: 0.02em;

  > a {
    color: #000;
    text-decoration: none;
  }
`

export const H1 = styled(Heading)`
  font-size: 2.2rem;
  line-height: 1.2;
  margin-bottom: 10px;
`

export const H2 = styled(Heading)`
  line-height: 1.25;
  margin-bottom: 0;
  font-size: 1.4rem;
  margin-bottom: 0;
  margin-top: 1.6em;

  :after {
    content: '_';
  }
`

export const WorkHeading = styled(H2)`
  margin-top: 1.6em;
`

export const H3 = styled(Heading)`
  margin-top: 0;
  font-size: 1.2rem;
  line-height: 1.3;
  margin-bottom: 0;
`

export const Link = styled.a`
  color: #000;
  text-decoration: underline;
  text-underline-position: under;

  :focus {
    color: #606c76;
  }

  :hover {
    color: #606c76;
  }
`

export const HeadingLink = styled(Link)`
  text-decoration: none;
`

export const InlineList = styled.ul`
  margin: 1rem 0 2rem;
  padding: 0;
  :last-child {
    margin-bottom: 0;
  }
`

export const InlineListElement = styled.li`
  display: inline;
  :nth-child(n + 2):before {
    content: ', ';
  }
`

export const Footer = styled.footer`
  position: fixed;
  z-index: 11;
  bottom: 20px;
  left: 20px;
  right: 20px;
  padding: 2em 0 1em;
  text-align: center;
  background: #ee6d6d; /*#ee6156;*/
`

export const Header = styled.header`
  position: fixed;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;

  text-rendering: optimizeLegibility;
  font-feature-settings: 'kern' 1, 'kern';
  font-kerning: normal;
  letter-spacing: 0.02em;

  background: #ee6d6d; /*#ee6156;*/
  padding: 3rem;
  color: #000;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  vertical-align: middle;
  justify-content: center;

  > div {
    max-width: 900px;
  }
`

export const Time = styled.time`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.8em;
`

export const Address = styled.address`
  font-style: inherit;
  margin: 1rem 0 2rem;
`

export const Main = styled.main`
  position: relative;
  z-index: 12;
  margin-top: calc(100vh - 60px - 40px);
  margin-bottom: 100vh;
  padding: 0 30px;
`

export const SkillsList = styled.ul`
  padding: 0;
  margin-left: 0;
`

export const SkillsItem = styled.li`
  position: relative;
  display: block;
  list-style: none;
  width: 100%;
  padding: 0;
  color: #fff;
  background: #fff;

  :nth-child(n + 2) {
    margin-top: 0.5rem;
  }
`

export const SkillsItemSpan = styled.span`
  display: block;
  background: #45484d;
  padding: 1px 2px 1px 4px;
  font-size: 0.8em;
  line-height: 1.6;
`

export const Grid = styled.div`
  @media screen and (min-width: 650px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  overflow: hidden;
  z-index: 0;
  background: #fff;
  position: relative;
`

export const GridFullWidth = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
`

export const Article = styled.article`
  margin: 3rem;
  max-width: 630px;
`

export const AsideWrap = styled.div`
  background: #efefef;
  grid-row-start: 1;
  grid-column-start: 2;
  justify-self: stretch;
  display: grid;

`

export const Aside = styled.aside`
  margin: 3rem;

  @media screen and (min-width: 650px) {
    max-width: 270px;
    margin: 0;

    > div {
      margin: 3rem 3rem 0 3rem;
    }
  }
`

export const Wrap = styled.div`
  overflow: hidden;
  background: #fff;
  z-index: 0;
  position: relative;

  @media screen and (min-width: 650px) {
    display: grid;
    grid-template-rows: calc(50% + 270px) calc(50% - 270px);
    grid-column-gap: 0;
    grid-row-gap: 0;
    justify-items: end;
  }
`

export const WrapInner = styled.div`
  justify-items: end;
  align-items: end;
  justify-content: end;
`

export default () => (
  <section>
    <H1>H1 - This is a heading</H1>
    <hr />

    <H2 as="h2">H2 - This is a heading</H2>
    <hr />

    <H3 as="h3">H3 - This is a heading</H3>
    <hr />
    <Link href="https://google.com">This is a link</Link>
    <hr />

    <HeadingLink href="https://google.com">This is a link</HeadingLink>
    <hr />

    <InlineList>
      <InlineListElement>This is the first element</InlineListElement>
      <InlineListElement>Second element</InlineListElement>
      <InlineListElement>Third element</InlineListElement>
    </InlineList>
    <hr />

    <Time>2014-07-04 — 2016-07-03</Time>
    <hr />

    <SkillsList>
      <SkillsItem>
        <SkillsItemSpan>CSS</SkillsItemSpan>
      </SkillsItem>
      <SkillsItem>
        <SkillsItemSpan>CSS</SkillsItemSpan>
      </SkillsItem>
      <SkillsItem>
        <SkillsItemSpan>CSS</SkillsItemSpan>
      </SkillsItem>
      <SkillsItem>
        <SkillsItemSpan>CSS</SkillsItemSpan>
      </SkillsItem>
      <SkillsItem>
        <SkillsItemSpan>CSS</SkillsItemSpan>
      </SkillsItem>
    </SkillsList>
    <hr />

    <Grid>
      <GridFullWidth>
        <H1>A long Full width heading</H1>
      </GridFullWidth>
      <div>foo</div>
      <div>bar</div>
    </Grid>
    <hr />
  </section>
)
